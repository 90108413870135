<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="4"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="openTeamModel()"
            >
              <span>{{ $t('ADD_TEAM') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-alert
              v-if="isAllUser"
              variant="info"
              show
              class="ml-1"
            >
              <div class="alert-body">
                <span>{{ $t('EVERYONE') }}
                </span>
              </div>
            </b-alert>
          </b-col>
          <!-- Search Project-->
          <b-col
            cols="8"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown
                v-show="userData.userRoleId===userRole.ADMIN"
                variant="link"
                no-caret
                class="chart-dropdown"
                toggle-class="p-0"
              >
                <template #button-content>
                  <feather-icon
                    size="17"
                    icon="MoreVerticalIcon"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item
                  v-model="isAllUser"
                  @click="handleUserAll(false)"
                >
                  {{ $t('ONLY_COMPANY') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-model="isAllUser"
                  @click="handleUserAll(true)"
                >
                  {{ $t('EVERYONE') }}
                </b-dropdown-item>
              </b-dropdown>
              <v-select
                v-model="project"
                :options="allProjects"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_PROJECT')"
                @input="handleProjectSelectChange()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <b-row>
        <b-col
          v-for="(team,index) in allTeams"
          :key="index"
          lg="4"
          md="6"
        >
          <b-card class="card-app-design">

            <!-- design group -->
            <div class="design-group">
              <h6 class="section-label">
                {{ mapTeamRoleToVariant(team.teamCoach).description_2 }}
              </h6>
              <b-badge
                :key="index"
                :variant="changeVariant(index)"
                class="mr-1"
              >
                {{ team.name }}
              </b-badge>
              <feather-icon
                icon="EditIcon"
                size="16"
                class="cursor-pointer  mr-1"
                @click="openTeamModel(team)"
              />
              <feather-icon
                icon="Trash2Icon"
                size="16"
                class="cursor-pointer  mr-1"
                @click="handleTeamDelete(team)"
              />
            </div>
            <div class="design-group">
              <div>
                <h6 class="section-label">
                  {{ mapTeamRoleToVariant(team.teamCoach).description_1 }}
                  <feather-icon
                    icon="UserPlusIcon"
                    size="16"
                    class="cursor-pointer ml-1"
                    @click="openNewPlayerModel(team)"
                  />
                  <feather-icon
                    v-if="team.teamUsers.length>0"
                    icon="UserMinusIcon"
                    size="16"
                    class="cursor-pointer ml-1"
                    @click="openPlayerModel(team)"
                  />
                </h6>
              </div>
            </div>
            <b-badge
              v-for="(teamUser,userIndex) in team.teamUsers"
              :key="userIndex"
              :variant="changeVariant(index)"
              class="mr-1"
            >
              {{ teamUser.user.firstName + ' ' + teamUser.user.lastName.charAt(0) }}
            </b-badge>
          </b-card>
        </b-col>
      </b-row>
      <team-model
        :fetch-teams="fetchTeams"
        :project="project"
        :selected-team="selectedTeam"
      />
      <player-model
        :fetch-teams="fetchTeams"
        :project="project"
        :selected-team="selectedTeam"
      />
      <new-player-model
        :all-team-users="allTeamUsers"
        :fetch-teams="fetchTeams"
        :project="project"
        :selected-team="selectedTeam"
        :is-all-user="isAllUser"
      />
    </b-card>
    <b-card
      no-body
    ><team-view
      :all-team-users="allTeamUsers"
      :fetch-teams="fetchTeams"
      :project="project"
    />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BRow, BCol,
  BBadge,
  BDropdown,
  BDropdownItem,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import constants from '@/constants/static.json'
import { mapTeamRoleToVariant, mapTeamUsers } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import mixinAlert from '@/constants/mixinAlert'
import TeamModel from './TeamModel.vue'
import PlayerModel from '../team-configuration/PlayerModel.vue'
import NewPlayerModel from '../team-configuration/NewPlayerModel.vue'
import TeamView from '../team-view/TeamView.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    vSelect,
    TeamModel,
    PlayerModel,
    NewPlayerModel,
    TeamView,
    BDropdown,
    BDropdownItem,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      allProjects: [],
      allTeamUsers: [],
      isAllUser: false,
      userRole: constants.USER_ROLE,
      project: {
        id: 0,
        name: '',
        start: '',
        end: '',
        companyId: 0,
        description: '',
      },
      userData: useJwt.getUser(),
      emptyTeam: {
        id: 0,
        projectId: 0,
        name: '',
        description: '',
        colour: '',
        teamCoach: false,
      },
      selectedTeam: {},
    }
  },
  computed: {
    ...mapState('team', ['allTeams']),
  },
  async mounted() {
    try {
      const response = await this.fetchAllProjects()
      if (response && response.data) {
        this.allProjects = response.data
        const routeId = router.currentRoute.params.id
        if (routeId) {
          this.project = this.allProjects.find(item => item.id === routeId)
        }
        this.handleProjectSelectChange()
      }
    } catch (error) {
      console.error('Error fetching projects:', error)
    }
  },
  methods: {
    ...mapActions('project', [
      'fetchAllProjects']),
    ...mapActions('team', [
      'deleteTeam',
      'fetchAllTeams']),
    // eslint-disable-next-line consistent-return
    async fetchTeams() {
      try {
        if (this.project.id) {
          await this.fetchAllTeams(this.project.id)
          this.transformTeamData()
        }
      } catch {
        this.showErrorMessage()
      }
    },
    handleUserAll(value) {
      this.isAllUser = value
    },
    openTeamModel(data) {
      if (data) {
        this.selectedTeam = { ...data }
      } else {
        this.selectedTeam = { ...this.emptyTeam }
      }
      this.$bvModal.show('team-modal')
    },
    openPlayerModel(data) {
      if (data) {
        this.selectedTeam = { ...data }
      }
      this.$bvModal.show('player-modal')
    },
    openNewPlayerModel(data) {
      if (data) {
        this.selectedTeam = { ...data }
      }
      this.$bvModal.show('new-player-modal')
    },
    handleTeamDelete(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  team ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteTeam(data.id).then(response => {
              if (response) {
                this.fetchTeams()
                this.successMessage(this.$i18n.t('MESSAGE.TEAM_DELETED'))
              }
            }).catch(() => {
              this.errorMessage(this.$i18n.t('MESSAGE.TEAM_ATTACHED'))
            })
          }
        })
    },
    async handleProjectSelectChange() {
      if (this.project) {
        this.fetchTeams()
      }
    },
    transformTeamData() {
      this.allTeamUsers = this.mapTeamUsers(this.allTeams)
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      mapTeamRoleToVariant,
      mapTeamUsers,
    }
  },

}
</script>
